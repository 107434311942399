import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbWindowRef, NbWindowService } from '@nebular/theme';
import { LibraryService } from '../../_services/library.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ngx-blank-popup-ent',
  templateUrl: './blank-popup-ent.component.html',
  styleUrls: ['./blank-popup-ent.component.scss']
})
export class BlankPopupEntComponent implements OnInit {

  constructor(private windowService: NbWindowService, private libService: LibraryService) { }

  ngOnInit(): void {

    console.log("IN DIALOG");

  }
  openWindow() {
    this.windowService.open(BlankPopupEntComponent);
  }
}
