import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogService, NbWindowService } from '@nebular/theme';
import { EntertainmentpopupComponent } from '../entertainmentpopup/entertainmentpopup.component';
import { BlankPopupEntComponent } from './blank-popup-ent/blank-popup-ent.component';

@Component({
  selector: 'ngx-accbutton',
  templateUrl: './accbutton.component.html',
  styleUrls: ['./accbutton.component.scss']
})
export class AccbuttonComponent implements OnInit {
  @Input() Data
  @Output() Event = new EventEmitter();
  
  constructor(private windowService: NbWindowService) { }
  
  ngOnInit(): void {
    console.log("IN DIALOG");
  }
  onClickFunction(){
  const windowRef = this.windowService.open(BlankPopupEntComponent, {});
    windowRef.onClose.subscribe((bool) => {
      console.log(">>>>>>>>>",bool)
    });
  } 
}

