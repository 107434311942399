import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LibDialogueComponent } from '../pages/library/lib-dialogue/lib-dialogue.component';
import { EditpopupComponent } from '../pages/library/editpopup/editpopup.component';
import { NbDialogService, NbWindowService } from '@nebular/theme';
import { BlankPreviewPopupComponent } from '../pages/library/blank-preview-popup/blank-preview-popup.component';

@Component({
  selector: 'ngx-previewbutton',
  templateUrl: './previewbutton.component.html',
  styleUrls: ['./previewbutton.component.scss']
})
export class PreviewbuttonComponent implements OnInit {
@Input() rowData
@Output() emitEvent = new EventEmitter();
  
  constructor( private windowService: NbWindowService) { }

  ngOnInit(): void {
  }
  onClickFunction(){
    // this.emitEvent.emit(rowData)

    const windowRef = this.windowService.open(BlankPreviewPopupComponent, {});
    windowRef.onClose.subscribe((bool) => {
      console.log(">>>>>>>>>",bool)
    });

  }
}
