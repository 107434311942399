<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <!-- <a class="logo" href="#" (click)="navigateHome()">ngx-<span>admin</span></a> -->
    <img src="assets/images/bizly_tv.png" style="height:65px;width: 100%; text-align: left;">
    
  </div>


  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
  <input type="checkbox" class="checkbox" id="checkbox" (click)="changeTheme(currentTheme)">
  <label for="checkbox" class="checkbox-label">
    <i class="fas fa-sun"></i>
    <i class="fas fa-moon"></i>
    <span class="ball"></span>
  </label>

<!-- 
  <label class="switch">
    <input type="checkbox" (click)="changeTheme(currentTheme)" >
    <span class="slider round"></span>
  </label> -->

</div>

<div class="header-container">
  <nb-actions size="small">

    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action> -->
    <nb-action class="control-item" icon="bell-outline"></nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
