import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { NbDialogService, NbWindowRef } from '@nebular/theme';
// import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { type } from 'os';
import { Previewbutton2Component } from '../pages/library/previewbutton2/previewbutton2.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LibraryService } from '../_services/library.service';
import { NbIconModule } from '@nebular/theme';


// @NgModule({
//   imports: [
//     // ...
//     NbIconModule,
//   ],



@Component({
  selector: 'ngx-entertainmentpopup',
  templateUrl: './entertainmentpopup.component.html',
  styleUrls: ['./entertainmentpopup.component.scss']


})
export class EntertainmentpopupComponent implements OnInit {

  EntDialogPopupForm: FormGroup;
  @Input() isDNDEnabled: boolean
  @Output() cellClick = new EventEmitter<any>();

  settings3 = {
    actions: false, ////create library table

    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      // id: {
      //   title: 'ID',
      //   type: 'number',
      // },
      Name: {
        title: 'Media Name',
        type: 'custom',
        // filter:false,
      },
      Type: {
        title: ' Type',
        type: 'string',
        // filter:false,
      },
      Duration: {
        title: 'Duration',
        type: 'string',
        // filter:false,
      },
      // Action: {
      //   title: 'Action',
      //   type: 'string',
      // },
      Preview: {
        title: 'Preview',
      },


    },
    // actions: {
    //   position: 'right', 
    //   filter:true,
    //  } 
    //   
  };

  source3: LocalDataSource = new LocalDataSource();

  constructor(private formBuilder: FormBuilder, protected windowRef: NbWindowRef, private libService: LibraryService) { }

  ngOnInit(): void {
    console.log("IN DIALOG");
    this.EntDialogPopupForm = this.formBuilder.group({
      Name: new FormControl(''),
      Status: new FormControl(''),
    })
  }
  onSubmit(){}
}
