<div class="background">
  <form class="col-md-12" [formGroup]="EntDialogPopupForm" action="" method="get" (ngSubmit)="onSubmit()">
    <div class="row offset-md-2 col-md-10">
      <div class="form-group row col-md-12">
        <label for="inputName" class="col-sm-3 col-form-label details" style="line-height: 13px;">Library Name</label>
        <div class="col-sm-9">
          <input type="Name" class="form-control form" id="inputName" placeholder="Name">
        </div>
      </div>
      <div class="form-group row col-md-12">
        <label for="inputStatus" class="col-sm-3 col-form-label details">Status</label>
        <div class="col-sm-9 alignContent">
          <br>
          <div class="custom-control custom-radio custom-control-inline ">
            <input type="radio" id="customRadioInline1" name="customRadioInline1" class="custom-control-input">
            <label class="custom-control-label" for="customRadioInline1">Active</label>
          </div>

          <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="customRadioInline2" name="customRadioInline1" class="custom-control-input">
            <label class="custom-control-label" for="customRadioInline2">Stop</label>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="row col-md-12 col-sm-12">
      <button type="submit" class="btn btn-primary" id="addMedia"> Add Media</button>
    </div>
    <br>
    <!-- <div> -->
    <div class="row">
      <ng2-smart-table [settings]="settings3" [source]="source3"></ng2-smart-table>
    </div>
    <div class="row col-md-12 col-sm-12" style="display:flex; justify-content: center; margin-top: 5px;">
      <button type="submit" class="btn btn-success btn-lg button">Save</button>
    </div>
  </form>
</div>
