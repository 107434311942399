import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService, NbWindowService } from '@nebular/theme';
import { LibraryService } from '../../../_services/library.service';

@Component({
  selector: 'ngx-blank-preview-popup',
  templateUrl: './blank-preview-popup.component.html',
  styleUrls: ['./blank-preview-popup.component.scss']
})
export class BlankPreviewPopupComponent implements OnInit {
  // BlankPreviewPopupComponent: any;

  Preview: String;
  constructor(private windowService: NbWindowService, private libService: LibraryService) { }
  ngOnInit(): void {

    console.log("IN DIALOG");
  }

  openWindow() {
    this.windowService.open(Component, { title: `Window` });
  }
}
